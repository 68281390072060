const legoCategories = [
    'Please select!',
    'City',
    'Creator',
    'Star Wars',
    'Chima',
    'Ferrari',
    'Bionicle',
    'Promo',
    'Dots',
    'Mixels',
];

export default legoCategories;